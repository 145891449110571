import React from "react"
import { graphql, PageProps } from "gatsby"
import Layout from "../../components/layout"
import Header from "../../components/header"
import Footer from "../../components/footer"
import ArticlePreview from "../../components/article-preview"
import { articlesPageSettings } from "./articles-page-settings"
import { ContentWrapper, Heading, MainContent } from "../../ui"
import {
  ArticlesPageQuery,
  IBanner,
  ICategory,
  IFooter,
  IMediaAsset,
  INavigationBar,
  Scalars,
} from "../../../gatsby-graphql"
import { Articles } from "./articles-page-elements"
import CategoriesBar from "../../components/categories-bar"
import SectionLoader from "../../sections/section-loader"
import { ILocaleLink } from "../../components/top-bar/common/Locales"
import { LayoutStyles } from "./articles-page-styles"

interface PageContext {
  locale: string
  locales: ILocaleLink[]
  category: ICategory | null
}

interface ArticlesPageProps extends PageProps {
  data: ArticlesPageQuery
  pageContext: PageContext
}

const ArticlesPage = ({ data, pageContext }: ArticlesPageProps) => {
  const basePath = `/${data.articlesPage?.locale}/${data.articlesPage?.slug}/`
  return (
    <Layout topBar={data.topBar} seoTitle={"/articles-page"} seo={data.articlesPage?.seo || undefined} locales={pageContext.locales} additionalStyles={LayoutStyles} locale={pageContext.locale}>
      <Header
        data={data.articlesPage?.header as IBanner}
        navBarData={data.navBar as INavigationBar}
        className={articlesPageSettings.headerClass}
        locales={pageContext.locales}
        rwdLogo={data.global?.logo as IMediaAsset}
        locale={pageContext.locale}/>
      <MainContent>
        <CategoriesBar
          data={data.articlesPage?.categories as ICategory[]}
          basePath={basePath}
          active={pageContext.category?.slug}
        />
        <Articles>
          <ContentWrapper maxWidth={articlesPageSettings.maxContentWidth}>
            {data.articles.nodes.length > 0 &&
              data.articles.nodes.map((article, index) => (
                <ArticlePreview
                  data={article}
                  basePath={basePath}
                  key={index}
                />
              ))}
          </ContentWrapper>
        </Articles>
        {data.articlesPage?.sections.length > 0 &&
          data.articlesPage?.sections.map(
            (section: Scalars["JSON"], index: number) => (
              <SectionLoader
                data={section}
                locale={pageContext.locale}
                key={index}
              />
            )
          )}
      </MainContent>
      <Footer data={data.footer as IFooter}
              locales={pageContext.locales}
              locale={pageContext.locale}
      />
    </Layout>
  )
}

export default ArticlesPage

export const pageQuery = graphql`
  query ArticlesPage(
    $id: String!
    $locale: String!
    $articlesPerPage: Int!
    $offset: Int!
    $categoryIdFilter: IntQueryOperatorInput
  ) {
    global: strapiGlobal {
      ...FGlobal
    }
    topBar: strapiTopBar(locale: { eq: $locale }) {
      ...FTopBar
    }
    navBar: strapiNavigationBar(locale: { eq: $locale }) {
      ...FNavigationBar
    }
    footer: strapiFooter(locale: { eq: $locale }) {
      ...FFooter
    }
    articlesPage: strapiArticlesPage(id: { eq: $id }) {
      ...FArticlesPage
    }
    articles: allStrapiArticle(
      limit: $articlesPerPage
      skip: $offset
      sort: { fields: created_at, order: DESC }
      filter: { locale: { eq: $locale }, category: { id: $categoryIdFilter } }
    ) {
      nodes {
        ...FArticle
      }
    }
  }
  fragment FArticlesPage on StrapiArticlesPage {
    id
    title
    slug
    locale
    seo {
      ...FSeo
    }
    header {
      ...FBanner
    }
    topArticles {
      ...FArticles # TODO
    }
    categories {
      ...FCategory
    }
    articlesPerPage
    sections
  }
`
