import styled from "styled-components"
import { BreakPoints, media } from "../../helpers/rwd"
import { ArticlesStyles } from "./articles-page-styles"

export const Articles = styled.section`
  margin: 35px 0;
  ${media.max(BreakPoints.tablet)} {
    margin: 15px 0;
  }
  ${ArticlesStyles}
`
